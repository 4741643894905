import { defineStore } from 'pinia'
import type {
  Address,
  AddressProperties,
  NormalizedCoordinatesObject,
  NormalizedCoordinatesArray,
  Feature,
} from "../models/Location.interface"
import axios, { type AxiosResponse } from "axios"

interface AddressState {
  addresses: Address[]
  location: Address
  locations: Address[]
  recentLocations: Address[]
  geoLocation: NormalizedCoordinatesObject
}

export const useAddressStore = defineStore('address', {
  state: (): AddressState => ({
    addresses: [],
    location: {
      properties: {},
    },
    locations: [],
    recentLocations: [],
    geoLocation: {
      latitude: undefined,
      longitude: undefined,
    }
  }),
  actions: {
    setAddresses (addresses: Address[]) {
      this.addresses = addresses
    },
    setLocations (locations: Address[]) {
      this.locations = locations
    },
    updateLocation (location: Address) {
      this.location = location
    },
    setRecentLocations (location: Address) {
      let recentLocations = this.recentLocations
      recentLocations = recentLocations.filter((item: Address) => {
        return (
          item.properties.department !== location.properties.department &&
          item.properties.city !== location.properties.city
        )
      })
      if (recentLocations.length === 3) {
        recentLocations.pop()
      }

      recentLocations.unshift(location)
      this.recentLocations = recentLocations
    },
    setGeolocation (geoLocation: NormalizedCoordinatesObject) {
      this.geoLocation = geoLocation
    },
    setLocation (location: Address) {
      this.updateLocation(location)
      this.setRecentLocations(location)
    },
    async fetchAddresses (query: string) {
      const response = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          query.replace(/ /gm, "+")
        )}&limit=5&autocomplete=1`
      )

      if (response.status === 200) {
        const addresses = response.data.features.map((feature: Feature) => ({
          properties: {
            city: feature.properties.city,
            department: feature.properties.context?.split(",")[1].trim(),
            departementCode: feature.properties.postcode?.substring(0, 2),
            district: feature.properties.codesPostaux,
            geometry: feature.geometry.coordinates,
            id: `${feature.properties.postcode?.substring(0, 2) ?? ""}_${feature.properties.name ?? ""
              }`,
            label: feature.properties.label,
            name: feature.properties.name,
            score: feature.properties._score,
            type: feature.properties.type,
            zipcode: feature.properties.postcode,
          },
        })) as Address[]

        this.setAddresses(addresses)
        return addresses
      }
      console.error(response)
    },
    async fetchLocations (query: string) {
      return await axios
        .get(
          `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
            query.replace(/ /gm, "+")
          )}&limit=5&type=municipality&autocomplete=1`
        )
        .then((response) => {
          const addresses = response.data.features.map((feature: Feature) => ({
            properties: {
              city: feature.properties.city,
              department: feature.properties.context?.split(",")[1].trim(),
              departementCode: feature.properties.postcode?.substring(0, 2),
              district: feature.properties.codesPostaux,
              geometry: feature.geometry.coordinates,
              id: `${feature.properties.postcode?.substring(0, 2) ?? ""}_${feature.properties.name ?? ""
                }`,
              label: feature.properties.label,
              name: feature.properties.name,
              score: feature.properties._score,
              type: feature.properties.type,
              zipcode: feature.properties.postcode,
            },
          })) as Address[]

          this.setLocations(addresses)

          return addresses
        })
        .catch((error) => {
          console.error(error)
          throw error
        }) // Data gouv return a network error if (query.length < 3)...
    },
    async reverseGeoLoc (coords: GeolocationCoordinates) {
      const baseUrl = "https://api-adresse.data.gouv.fr/reverse"

      const response = await axios.get(
        `${baseUrl}/?lat=${coords.latitude}&lon=${coords.longitude}`
      )

      if (response.status === 200) {
        return response.data?.features[0].properties as AddressProperties | null
      } else {
        console.error(response)
      }
    },
    async getLatLongWithCity (city: string) {
      const searchQuery: string = city.replace(/ /gm, "+")

      const response: AxiosResponse = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          searchQuery
        )}&limit=1&type=municipality&autocomplete=1`
      )

      const {
        data: { features: addresses },
      } = response

      // Returned coordinates from datagouv API are inverted
      const [longitude, latitude] = addresses[0].geometry.coordinates

      // Update map coordinates
      this.setGeolocation({ latitude, longitude })

      return [longitude, latitude] as NormalizedCoordinatesArray
    },
    async fetchManualAddress ({
      address,
      zipCode,
      city,
    }: { address: string; zipCode: string; city: string }) {
      const queryString = `${address} ${zipCode} ${city}`
      const searchQuery = queryString.replace(/ /gm, "+")

      const response: AxiosResponse = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          searchQuery
        )}&limit=1`
      )

      const {
        data: { features: addresses },
      } = response
      // Returned coordinates from datagouv API are inverted
      const [longitude, latitude] = addresses[0].geometry.coordinates

      // Update map coordinates
      this.setGeolocation({ latitude, longitude })

      return {
        address: addresses[0].properties.name,
        zipCode: addresses[0].properties.postcode,
        city: addresses[0].properties.city,
        coordinates: [latitude, longitude]
      }
    }
  }
})
